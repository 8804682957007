export const config = {

  version: '1.2.85',

  common: {
    // Time out value (in milliseconds) for calls from the http service to
    // the backend API
    httpApiServiceTimeOut: 300000, //30000,

    // The window title prefix that the SPA will use
    documentTitlePrefix: 'Realitex200',
  },
  dev: {

    // Hosted application path to root of the application
    // Set / for root or add the app path i.e. /someapp
    // where the application was hosted in the application someapp
    // in the default website for example
    appName: '/',
    // Base Url of the API host (including Identity Server), i.e. https://api.somesite.com
    // if different than the SPA host. Leave blank if same
    baseUrl: 'https://localhost:44353',
    localStorageCipherKey: '5p%nx5U5^gaZHn%_@dQPN!zWV_VqN?6H',
    postCodeLookupProvider: 'AFD',
    postCodeLookupProvideroptions: 'None,AFD,Loqate'
  },

  build: {
    // Base Url of the API host, i.e. https://api.somesite.com
    // if different than the SPA host. Leave blank if same
    //baseUrl: 'https://api.kellars.co.uk',

    // Hosted application path to root of the application
    // Set / for root or add the app path i.e. /someapp
    // where the application was hosted in the application someapp
    // in the default website for example
    //appName: '/'
    localStorageCipherKey: '5p%nx5U5^gaZHn%_@dQPN!zWV_VqN?6H',
    postCodeLookupProvider: 'AFD',
    postCodeLookupProvideroptions: 'None,AFD,Loqate'
  }
}
